import React, { useState } from 'react';
import {
    Box,
    TextField,
    Chip,
    IconButton,
    InputAdornment,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function UsernameTagInput({ 
    value = { users: [] }, 
    onChange, 
    error = false, 
    helperText,
    disabled = false
}) {
    const [inputValue, setInputValue] = useState('');
    const usernames = value?.users || [];

    const handleAddUsername = () => {
        const username = inputValue.trim().toLowerCase();
        if (username && !usernames.includes(username)) {
            const newUsernames = [...usernames, username];
            onChange({ users: newUsernames });
            setInputValue('');
        }
    };

    const handleDeleteUsername = (usernameToDelete) => {
        const newUsernames = usernames.filter(username => username !== usernameToDelete);
        onChange({ users: newUsernames });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAddUsername();
        }
    };

    return (
        <Box>
            <TextField
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Введите имя пользователя"
                error={error}
                helperText={helperText}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleAddUsername}
                                edge="end"
                                disabled={disabled}
                                sx={{
                                    color: 'primary.main',
                                    '&:hover': { backgroundColor: 'rgba(185, 117, 255, 0.1)' },
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {usernames.length > 0 && (
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {usernames.map((username) => (
                        <Chip
                            key={username}
                            label={username}
                            onDelete={disabled ? undefined : () => handleDeleteUsername(username)}
                            sx={{
                                backgroundColor: 'rgba(185, 117, 255, 0.1)',
                                '& .MuiChip-deleteIcon': {
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'error.main',
                                    },
                                },
                            }}
                        />
                    ))}
                </Box>
            )}
            {usernames.length > 0 && (
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                    {usernames.length} пользователей добавлено
                </Typography>
            )}
        </Box>
    );
} 