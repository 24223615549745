import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    Alert,
    Slider,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Requester from '../../../utils/requester';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { useNotification } from '../../../context/NotificationContext';
import UsernameTagInput from '../../../components/UsernameTagInput';
import { useUser } from '../../../context/UserContext';

const MAX_TOTAL_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const MAX_FILES = 1;

export default function CustomVoiceForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { hasFeatureAccess, loading: userLoading } = useUser();
    const isEditing = id !== undefined;
    const [formData, setFormData] = useState({
        voice_name: '',
        stability: 0.5,
        similarity_boost: 0.75,
        style: 0.0,
        use_speaker_boost: true,
        is_private: true,
        only_allowed_users: false,
        allowed_rules: { users: [] },
    });

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVoiceData = async () => {
            // Only check access after user data is loaded
            if (!userLoading && !hasFeatureAccess('voiceover_elevenlabs_custom')) {
                showNotification('Для создания своих голосов необходима подписка, которая включает функцию "Свой голос"', {
                    type: 'warning',
                    autoHideDuration: 5000
                });
                navigate('/dashboard/voices');
                return;
            }
            if (!isEditing) return;
            
            try {
                const response = await Requester.get(`tts/elevenlabs/voices/${id}/`);
                if (response.status === 200) {
                    const data = await response.json();
                    setFormData({
                        voice_name: data.voice_name,
                        stability: data.stability,
                        similarity_boost: data.similarity_boost,
                        style: data.style,
                        use_speaker_boost: data.use_speaker_boost,
                        is_private: data.is_private,
                        only_allowed_users: data.only_allowed_users,
                        allowed_rules: data.allowed_rules || { users: [] },
                    });
                }
            } catch (error) {
                console.error('Error fetching voice:', error);
                showNotification('Error loading voice data', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        };

        fetchVoiceData();
    }, [id, isEditing, showNotification, hasFeatureAccess, navigate]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        
        // Check number of files
        if (selectedFiles.length > MAX_FILES) {
            setError(`Можно загрузить только ${MAX_FILES} файл`);
            return;
        }

        // Check total size
        const totalSize = selectedFiles.reduce((sum, file) => sum + file.size, 0);
        if (totalSize > MAX_TOTAL_SIZE) {
            setError('Total file size must not exceed 10MB');
            return;
        }

        setFiles(selectedFiles);
        setError(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        if (!isEditing && (!files.length || files.length > MAX_FILES)) {
            setError(`Пожалуйста, выберите аудио файл`);
            return;
        }

        setLoading(true);
        try {
            const formDataToSend = new FormData();
            
            // Append files if any are selected (for both create and edit)
            if (files.length > 0) {
                files.forEach(file => {
                    formDataToSend.append('files', file);
                });
            }

            // Prepare the data to send
            const dataToSend = {
                voice_name: formData.voice_name,
                stability: Number(formData.stability),
                similarity_boost: Number(formData.similarity_boost),
                style: Number(formData.style),
                use_speaker_boost: formData.use_speaker_boost,
                is_private: formData.is_private,
                only_allowed_users: formData.only_allowed_users,
                allowed_rules: formData.allowed_rules,
            };

            console.log('Sending data:', dataToSend);

            // For PUT requests, we need to send JSON
            if (isEditing) {
                try {
                    // For PUT requests with files, use FormData
                    Object.entries(dataToSend).forEach(([key, value]) => {
                        if (typeof value === 'object') {
                            formDataToSend.append(key, JSON.stringify(value));
                        } else {
                            formDataToSend.append(key, String(value));
                        }
                    });

                    const response = await Requester.put(`tts/elevenlabs/voices/${id}/`, formDataToSend);
                    if (response.status === 200 || response.status === 201) {
                        showNotification('Голос успешно обновлен', {
                            type: 'success',
                            autoHideDuration: 3000
                        });
                        navigate('/dashboard/voices');
                    } else if (response.status === 400) {
                        const errorData = await response.json();
                        if (errorData.non_field_errors) {
                            showNotification(errorData.non_field_errors[0], {
                                type: 'error',
                                autoHideDuration: 5000
                            });
                        } else {
                            showNotification('Ошибка при обновлении голоса', {
                                type: 'error',
                                autoHideDuration: 3000
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error updating voice:', error);
                    showNotification('Ошибка при обновлении голоса', {
                        type: 'error',
                        autoHideDuration: 3000
                    });
                }
            } else {
                // For POST requests with files, use FormData
                Object.entries(dataToSend).forEach(([key, value]) => {
                    if (typeof value === 'object') {
                        formDataToSend.append(key, JSON.stringify(value));
                    } else {
                        formDataToSend.append(key, String(value));
                    }
                });

                try {
                    const response = await Requester.post('tts/elevenlabs/create/', formDataToSend);
                    if (response.status === 200 || response.status === 201) {
                        showNotification('Голос успешно создан', {
                            type: 'success',
                            autoHideDuration: 3000
                        });
                        navigate('/dashboard/voices');
                    } else if (response.status === 400) {
                        const errorData = await response.json();
                        if (errorData.non_field_errors) {
                            showNotification(errorData.non_field_errors[0], {
                                type: 'error',
                                autoHideDuration: 5000
                            });
                        } else {
                            showNotification('Ошибка при создании голоса', {
                                type: 'error',
                                autoHideDuration: 3000
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error creating voice:', error);
                    showNotification('Ошибка при создании голоса', {
                        type: 'error',
                        autoHideDuration: 3000
                    });
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field) => (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        console.log(`Changing ${field} to:`, value);
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSliderChange = (field) => (_, value) => {
        console.log(`Changing ${field} to:`, value);
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard/voices"
                            component={RouterLink}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                            Свои голоса
                        </Link>
                        <Typography color="primary">
                            {isEditing ? 'Изменить голос' : 'Создать новый голос'}
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <RecordVoiceOverIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            {isEditing ? 'Изменить голос' : 'Создать новый голос'}
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 3 }}>
                                        {error}
                                    </Alert>
                                )}
                                
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        {/* Voice Name */}
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Название голоса"
                                                fullWidth
                                                value={formData.voice_name}
                                                onChange={handleChange('voice_name')}
                                                required
                                                disabled={loading}
                                            />
                                        </Grid>

                                        {/* File Upload */}
                                        {!isEditing ? (
                                            <Grid item xs={12} md={6}>
                                                <input
                                                    accept="audio/*"
                                                    style={{ display: 'none' }}
                                                    id="voice-file-input"
                                                    multiple
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    disabled={loading}
                                                />
                                                <label htmlFor="voice-file-input">
                                                    <Button
                                                        variant="outlined"
                                                        component="span"
                                                        fullWidth
                                                        sx={{ height: '56px' }}
                                                        disabled={loading}
                                                    >
                                                        {files.length ? `${files.length} файл(ов) выбрано` : 'Загрузить аудио файл'}
                                                    </Button>
                                                </label>
                                                <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
                                                    Загрузите аудио файл (максимум 10МБ)
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} md={6}>
                                                <input
                                                    accept="audio/*"
                                                    style={{ display: 'none' }}
                                                    id="voice-file-input"
                                                    multiple
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    disabled={loading}
                                                />
                                                <label htmlFor="voice-file-input">
                                                    <Button
                                                        variant="outlined"
                                                        component="span"
                                                        fullWidth
                                                        sx={{ height: '56px' }}
                                                        disabled={loading}
                                                    >
                                                        {files.length ? `${files.length} файл(ов) выбрано` : 'Загрузить аудио файл (опционально)'}
                                                    </Button>
                                                </label>
                                                <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
                                                    Вы можете загрузить новый аудио файл который заменит существующий
                                                </Typography>
                                            </Grid>
                                        )}

                                        {/* Sliders */}
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Повышение стабильности сделает голос более последовательным между повторными генерациями, но может сделать его звучание монотонным. На длинных текстовых фрагментах рекомендуется понизить это значение." arrow>
                                                <Typography gutterBottom>Стабильность: {formData.stability}</Typography>
                                            </Tooltip>
                                            <Slider
                                                value={formData.stability}
                                                onChange={handleSliderChange('stability')}
                                                onChangeCommitted={handleSliderChange('stability')}
                                                min={0}
                                                max={1}
                                                step={0.1}
                                                marks
                                                valueLabelDisplay="auto"
                                                disabled={loading}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Высокое значение улучшает общую четкость голоса и схожесть с целевым голосом. Очень высокие значения могут вызвать артефакты, поэтому рекомендуется подобрать оптимальное значение." arrow>
                                                <Typography gutterBottom>Схожесть: {formData.similarity_boost}</Typography>
                                            </Tooltip>
                                            <Slider
                                                value={formData.similarity_boost}
                                                onChange={handleSliderChange('similarity_boost')}
                                                onChangeCommitted={handleSliderChange('similarity_boost')}
                                                min={0}
                                                max={1}
                                                step={0.1}
                                                marks
                                                valueLabelDisplay="auto"
                                                disabled={loading}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Высокие значения рекомендуются, если стиль речи должен быть преувеличен по сравнению с загруженным аудио. Более высокие значения могут привести к нестабильности в генерируемой речи. Установка значения 0.0 значительно увеличит скорость генерации и является настройкой по умолчанию." arrow>
                                                <Typography gutterBottom>Стиль: {formData.style}</Typography>
                                            </Tooltip>
                                            <Slider
                                                value={formData.style}
                                                onChange={handleSliderChange('style')}
                                                onChangeCommitted={handleSliderChange('style')}
                                                min={0}
                                                max={1}
                                                step={0.1}
                                                marks
                                                valueLabelDisplay="auto"
                                                disabled={loading}
                                            />
                                        </Grid>

                                        {/* Switches */}
                                        <Grid item xs={12}>
                                            <Tooltip title="Повышает схожесть синтезированной речи с голосом за счет некоторой потери скорости генерации." arrow>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={formData.use_speaker_boost}
                                                            onChange={handleChange('use_speaker_boost')}
                                                            disabled={loading}
                                                        />
                                                    }
                                                    label="Усиление голоса"
                                                />
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={formData.only_allowed_users}
                                                        onChange={handleChange('only_allowed_users')}
                                                        disabled={loading}
                                                    />
                                                }
                                                label="Только для выбранных пользователей"
                                            />
                                        </Grid>

                                        {formData.only_allowed_users && (
                                            <Grid item xs={12}>
                                                <UsernameTagInput
                                                    value={formData.allowed_rules}
                                                    onChange={(newValue) => {
                                                        console.log('New allowed rules:', newValue);
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            allowed_rules: newValue
                                                        }));
                                                    }}
                                                    error={false}
                                                    helperText="Добавьте пользователей, которым разрешено использовать этот голос"
                                                    disabled={loading}
                                                />
                                            </Grid>
                                        )}

                                        {/* Action Buttons */}
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => navigate('/dashboard/voices')}
                                                    disabled={loading}
                                                >
                                                    Отмена
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Сохранение...' : (isEditing ? 'Обновить' : 'Создать')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
} 
