import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Typography,
    Box,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    Alert,
    FormHelperText,
    Stepper,
    Step,
    StepLabel,
    Fade
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNotification } from '../../context/NotificationContext';
import Requester from '../../utils/requester';
import { styles, LIGHT_PURPLE } from './styles/GiftSubscriptionModal.styles';
import { isValidEmail } from './utils';

const steps = ['Канал', 'Период', 'Оплата'];

export default function GiftSubscriptionModal({ open, onClose, selectedPlan, allPlans }) {
    const [step, setStep] = useState(1);
    const [channelName, setChannelName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [currentChannelSubscription, setCurrentChannelSubscription] = useState(null);
    const [availablePlans, setAvailablePlans] = useState([]);
    const [selectedUpgradePlan, setSelectedUpgradePlan] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const { showNotification } = useNotification();

    const handleClose = () => {
        setStep(1);
        setChannelName('');
        setEmail('');
        setError('');
        setEmailError('');
        setCurrentChannelSubscription(null);
        setAvailablePlans([]);
        setSelectedUpgradePlan(null);
        setSelectedPeriod(null);
        onClose();
    };

    const validateChannel = async (planToValidate = selectedPlan) => {
        if (!channelName.trim()) {
            setError('Введите название канала, например: jeetbot');
            return;
        }

        setLoading(true);
        try {
            const response = await Requester.post('subscriptions/channel/exists/', {
                channel_name: channelName.trim(),
                subscription_type_id: planToValidate.id
            });
            const data = await response.json();

            if (!data.exists) {
                setError('Канал не найден');
                setLoading(false);
                return;
            }

            if (!data.can_gift) {
                if (data.current_subscription) {
                    setCurrentChannelSubscription(data.current_subscription);
                    // Filter plans that have a price greater than or equal to current subscription
                    const higherPlans = allPlans.filter(plan => 
                        parseFloat(plan.price) >= data.current_subscription.price
                    );
                    
                    if (higherPlans.length > 0) {
                        setAvailablePlans(higherPlans);
                        setSelectedUpgradePlan(null); // Reset selection to empty
                        setError('У канала уже есть подписка. Вы можете подарить подписку того же или более высокого уровня.');
                    } else {
                        setError('У канала уже есть подписка максимального уровня');
                    }
                } else {
                    setError(data.error || 'Невозможно подарить подписку этому каналу');
                }
                setLoading(false);
                return;
            }

            setError('');
            setStep(2);
        } catch (error) {
            setError('Ошибка при проверке канала');
            console.error('Error checking channel:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGiftSubscription = async () => {
        if (!email.trim()) {
            setEmailError('Введите email');
            return;
        }
        if (!isValidEmail(email)) {
            setEmailError('Введите корректный email');
            return;
        }
        setEmailError('');
        
        setLoading(true);
        try {
            const response = await Requester.post('subscriptions/gift/', {
                channel_name: channelName.trim(),
                subscription_type_id: selectedUpgradePlan?.id || selectedPlan.id,
                subscription_period_id: selectedPeriod.id,
                email: email.trim()
            });

            const data = await response.json();

            if (response.ok) {
                showNotification('Перенаправляем на страницу оплаты...', {
                    type: 'info',
                    autoHideDuration: 2000
                });
                window.location.href = data.confirmation_url;
            } else {
                if (data?.non_field_errors?.[0]) {
                    setEmailError(data.non_field_errors[0]);
                } else if (data?.email?.[0]) {
                    setEmailError(data.email[0]);
                } else if (data?.channel_name?.[0]) {
                    setEmailError(data.channel_name[0]);
                } else {
                    setEmailError('Произошла ошибка при оформлении подписки');
                }
            }
        } catch (error) {
            console.error('Error gifting subscription:', error);
            setEmailError('Произошла ошибка при оформлении подписки');
        } finally {
            setLoading(false);
        }
    };

    const handleNext = () => {
        if (step === 1) {
            // If there's a selected upgrade plan, validate with that plan instead
            if (currentChannelSubscription && selectedUpgradePlan) {
                validateChannel(selectedUpgradePlan);
            } else {
                validateChannel();
            }
        } else if (step === 2) {
            if (!selectedPeriod) {
                setError('Выберите период подписки');
                return;
            }
            setError('');
            setStep(3);
        } else if (step === 3) {
            handleGiftSubscription();
        }
    };

    const handleEmailKeyDown = (e) => {
        if (e.key === 'Enter' && !loading) {
            e.preventDefault();
            handleGiftSubscription();
        }
    };

    const renderSubscriptionSelector = () => {
        if (!currentChannelSubscription || availablePlans.length === 0) return null;

        return (
            <Box sx={{ mt: 3 }}>
                <FormControl fullWidth variant="outlined">
                    <Select
                        value={selectedUpgradePlan?.id || ''}
                        onChange={(e) => {
                            const plan = availablePlans.find(p => p.id === e.target.value);
                            setSelectedUpgradePlan(plan);
                        }}
                        displayEmpty
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(255, 255, 255, 0.4)',
                            },
                        }}
                    >
                        <MenuItem value="" disabled>
                            <em>Выберите уровень подписки</em>
                        </MenuItem>
                        {availablePlans.map((plan) => (
                            <MenuItem key={plan.id} value={plan.id}>
                                {plan.name} - {plan.price} р/месяц
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const renderPeriodSelector = () => {
        const currentPlan = selectedUpgradePlan || selectedPlan;
        const periods = currentPlan?.available_periods || [];
        
        if (!currentPlan || periods.length === 0) {
            return (
                <Alert 
                    severity="error" 
                    sx={styles.errorAlert}
                >
                    Не удалось загрузить периоды подписки. Пожалуйста, попробуйте позже.
                </Alert>
            );
        }
        
        return (
            <>
                <Typography variant="body1" sx={styles.planPrice}>
                    Подписка для канала <strong>{channelName}</strong>
                    <br />
                    План: <strong>{currentPlan.name}</strong>
                </Typography>
                <FormControl fullWidth error={!!error}>
                    <Select
                        value={selectedPeriod?.id || ''}
                        onChange={(e) => {
                            const period = periods.find(p => p.id === e.target.value);
                            setSelectedPeriod(period);
                            if (error) setError('');
                        }}
                        displayEmpty
                        sx={styles.select}
                    >
                        <MenuItem value="" disabled>
                            <em>Выберите период подписки</em>
                        </MenuItem>
                        {periods.map((period) => (
                            <MenuItem key={period.id} value={period.id}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    width: '100%', 
                                    alignItems: 'center'
                                }}>
                                    <span>{period.name}</span>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography component="span" sx={{ 
                                            color: 'grey.400', 
                                            fontSize: '0.9em',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}>
                                            {period.discount_percent > 0 && (
                                                <>
                                                    <span style={{ 
                                                        textDecoration: 'line-through', 
                                                        marginRight: '8px'
                                                    }}>
                                                        {(period.discounted_price / (1 - period.discount_percent / 100)).toFixed(0)} ₽
                                                    </span>
                                                    <span style={{ 
                                                        color: '#4CAF50', 
                                                        marginRight: '8px',
                                                        padding: '2px 6px',
                                                        borderRadius: '4px',
                                                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                                        fontSize: '0.85em'
                                                    }}>
                                                        -{period.discount_percent}%
                                                    </span>
                                                </>
                                            )}
                                        </Typography>
                                        <Typography component="span" sx={{ 
                                            fontWeight: 'bold',
                                            color: LIGHT_PURPLE
                                        }}>
                                            {period.discounted_price} ₽
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </>
        );
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{ sx: styles.dialog }}
        >
            <Box sx={styles.glowingBackground} />
            <Box sx={styles.gradientOverlay} />

            <DialogTitle sx={styles.dialogTitle}>
                <Typography 
                    variant="h5" 
                    component="div" 
                    sx={styles.titleText}
                >
                    Подарить подписку
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={styles.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={styles.dialogContent}>
                <Stepper 
                    activeStep={step - 1} 
                    sx={styles.stepper}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box sx={{ minHeight: '200px' }}>
                    <Fade in={step === 1} unmountOnExit>
                        <Box>
                            <Typography variant="h6" sx={styles.planTitle}>
                                Вы выбрали план: <strong>{selectedUpgradePlan?.name || selectedPlan?.name}</strong>
                            </Typography>
                            <Typography variant="body1" sx={styles.planPrice}>
                                Стоимость: <strong>{selectedUpgradePlan?.price || selectedPlan?.price} р/месяц</strong>
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Имя канала"
                                placeholder="Например: jeetbot"
                                fullWidth
                                variant="outlined"
                                value={channelName}
                                onChange={(e) => setChannelName(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !loading && !(currentChannelSubscription && !selectedUpgradePlan)) {
                                        e.preventDefault();
                                        handleNext();
                                    }
                                }}
                                error={!!error && !currentChannelSubscription}
                                helperText={!currentChannelSubscription ? error : null}
                                disabled={loading}
                                sx={styles.textField}
                            />
                            {currentChannelSubscription && !selectedUpgradePlan && (
                                <Alert 
                                    severity="warning" 
                                    sx={styles.warningAlert}
                                >
                                    {error}
                                </Alert>
                            )}
                            {renderSubscriptionSelector()}
                        </Box>
                    </Fade>

                    <Fade in={step === 2} unmountOnExit>
                        <Box>
                            {renderPeriodSelector()}
                        </Box>
                    </Fade>

                    <Fade in={step === 3} unmountOnExit>
                        <Box>
                            <Typography variant="body1" sx={styles.planPrice}>
                                Подписка для канала <strong>{channelName}</strong>
                                <br />
                                План: <strong>{selectedUpgradePlan?.name || selectedPlan?.name}</strong>
                                <br />
                                Период: <strong>{selectedPeriod?.name}</strong> - {selectedPeriod?.discounted_price} ₽
                                {selectedPeriod?.discount_percent > 0 && (
                                    <span style={{ color: '#4CAF50', marginLeft: '8px' }}>
                                        (скидка {selectedPeriod.discount_percent}%)
                                    </span>
                                )}
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Email для чека"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (emailError) {
                                        setEmailError('');
                                    }
                                }}
                                onKeyDown={handleEmailKeyDown}
                                error={!!emailError}
                                helperText={emailError}
                                disabled={loading}
                                sx={styles.textField}
                            />
                        </Box>
                    </Fade>
                </Box>
            </DialogContent>

            <DialogActions sx={styles.dialogActions}>
                <Button 
                    onClick={step === 1 ? handleClose : () => {
                        setStep(step - 1);
                        // Only show warning if we have a current subscription but no upgrade plan selected
                        if (step === 2 && currentChannelSubscription && !selectedUpgradePlan) {
                            setError('У канала уже есть подписка. Вы можете подарить подписку того же или более высокого уровня.');
                        }
                    }} 
                    sx={styles.cancelButton}
                >
                    {step === 1 ? 'Отмена' : 'Назад'}
                </Button>
                <Button
                    onClick={handleNext}
                    variant="contained"
                    disabled={loading || (currentChannelSubscription && !selectedUpgradePlan)}
                    sx={styles.nextButton}
                >
                    {loading ? (
                        <CircularProgress size={24} sx={{ color: 'white' }} />
                    ) : (
                        step === 3 ? 'Купить' : 'Далее'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
} 