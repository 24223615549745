import {redirect} from "react-router-dom";
import jwt_decode from "jwt-decode";

function get_local_storage(key) {
    let value = localStorage.getItem(key);
    if (value === 'undefined') {
        return null;
    }
    return value;
}

export default class Requester {
    static BASE_URL = process.env.REACT_APP_API_URL || `http://127.0.0.1:8000/dashboard/api/`;
    static DEFAULT_TIMEOUT = 30000; // 30 seconds
    static requestCache = new Map();
    static pendingRequests = new Map();

    // Token management
    static getToken() {
        return get_local_storage("token");
    }

    static getRefreshToken() {
        return get_local_storage("refreshToken");
    }

    static redirectToSignIn() {
        redirect("/");
    }

    // Token validation and refresh
    static async validateToken() {
        if (!this.getToken()) {
            if (this.getRefreshToken()) {
                return this.refreshToken();
            }
            return Promise.resolve(false);
        }

        /** @type {{exp: number}} */
        const decodedToken = jwt_decode(this.getToken());
        if (decodedToken.exp < Date.now() / 1000) {
            return this.refreshToken();
        }

        return Promise.resolve(true);
    }

    static async refreshToken() {
        if (!this.getRefreshToken()) {
            return Promise.resolve(false);
        }

        try {
            const response = await fetch(`${this.BASE_URL}token/refresh/`, {
                method: "POST",
                body: JSON.stringify({ refresh: this.getRefreshToken() }),
                headers: {
                    "Content-type": "application/json",
                }
            });

            if (response.status !== 200) {
                this.clearTokens();
                return false;
            }

            const data = await response.json();
            localStorage.setItem("token", data.access);
            return true;
        } catch (error) {
            console.error('Token refresh failed:', error);
            this.clearTokens();
            return false;
        }
    }

    static clearTokens() {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
    }

    // Request helpers
    /**
     * @param {Object} customHeaders
     * @param {*} body
     * @returns {Object.<string, string>}
     */
    static getHeaders(customHeaders = {}, body = null) {
        const headers = {
            ...customHeaders
        };

        // Only set Content-Type if body is not FormData
        if (!(body instanceof FormData)) {
            headers["Content-type"] = "application/json";
        }

        const token = this.getToken();
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        return headers;
    }

    static async handleResponse(response, url, options) {
        if (response.status === 401 || response.status === 403) {
            try {
                const data = await response.json();
                if (data.code === "token_not_valid" || response.status === 401) {
                    const refreshResult = await this.refreshToken();
                    if (refreshResult) {
                        return this.request(url, options);
                    }
                }
            } catch (error) {
                console.error('Error handling response:', error);
            }
        }
        return response;
    }

    // Main request method
    static async request(url, options = {}) {
        const {
            method = 'GET',
            body,
            headers = {},
            timeout = this.DEFAULT_TIMEOUT,
            cache = false,
            signal = null
        } = options;

        // Check cache
        const cacheKey = `${method}:${url}:${JSON.stringify(body)}`;
        if (cache && method === 'GET' && this.requestCache.has(cacheKey)) {
            return this.requestCache.get(cacheKey);
        }

        // Create abort controller for timeout
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const requestOptions = {
                method,
                headers: this.getHeaders(headers, body),
                signal: signal || controller.signal
            };

            if (body) {
                requestOptions.body = body instanceof FormData ? body : JSON.stringify(body);
            }

            const response = await fetch(`${this.BASE_URL}${url}`, requestOptions);
            const handledResponse = await this.handleResponse(response, url, options);

            // Cache successful GET requests
            if (cache && method === 'GET' && handledResponse.ok) {
                this.requestCache.set(cacheKey, handledResponse.clone());
            }

            return handledResponse;
        } catch (error) {
            if (error.name === 'AbortError') {
                throw new Error(`Request timeout after ${timeout}ms`);
            }
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    }

    // Convenience methods
    static async get(url, options = {}) {
        return this.request(url, { ...options, method: 'GET' });
    }

    static async post(url, data, options = {}) {
        return this.request(url, { ...options, method: 'POST', body: data });
    }

    static async put(url, data, options = {}) {
        return this.request(url, { ...options, method: 'PUT', body: data });
    }

    static async patch(url, data, options = {}) {
        return this.request(url, { ...options, method: 'PATCH', body: data });
    }

    static async delete(url, options = {}) {
        return this.request(url, { ...options, method: 'DELETE' });
    }

    // Cache management
    static clearCache() {
        this.requestCache.clear();
    }

    static clearCacheForUrl(url) {
        for (const key of this.requestCache.keys()) {
            if (key.includes(url)) {
                this.requestCache.delete(key);
            }
        }
    }
}