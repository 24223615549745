import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Alert,
    Breadcrumbs,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    Box,
    Button,
    TextField,
    IconButton,
    Collapse,
} from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LockIcon from '@mui/icons-material/Lock';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Requester from "../../../utils/requester";
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';

const REFRESH_INTERVAL = 10000; // 10 seconds

export default function BotManagement() {
    const { showNotification } = useNotification();
    const { hasFeatureAccess } = useUser();
    const [loading, setLoading] = useState(true);
    const [botInfo, setBotInfo] = useState(null);
    const [error, setError] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [isLinkingStarted, setIsLinkingStarted] = useState(false);
    const [showRelink, setShowRelink] = useState(false);

    const fetchBotInfo = async () => {
        try {
            const response = await Requester.get('channel/bot/');
            if (response.ok) {
                const data = await response.json();
                setBotInfo(data);
                // Reset linking state only if bot is connected and alive
                if (data && data.is_alive && isLinkingStarted) {
                    setIsLinkingStarted(false);
                    setRedirectUrl('');
                }
            } else if (response.status === 404) {
                setBotInfo(null);
            } else {
                setError('Не удалось получить информацию о боте');
            }
        } catch (error) {
            console.error('Error fetching bot info:', error);
            setError('Не удалось получить информацию о боте');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let intervalId = null;

        // Only fetch if user has access
        if (!hasFeatureAccess('owner_bot')) {
            setLoading(false);
            return;
        }

        // Initial fetch
        fetchBotInfo();

        // Set up auto-refresh
        if (isLinkingStarted) {
            intervalId = setInterval(fetchBotInfo, REFRESH_INTERVAL);
        }

        // Cleanup on unmount
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [hasFeatureAccess, isLinkingStarted]);

    const handleStartLinkingProcess = async () => {
        try {
            const response = await Requester.get('channel/bot/connect/');
            if (response.ok) {
                const data = await response.json();
                setRedirectUrl(data.redirect_url);
                if (isLinkingStarted) {
                    showNotification('Новая ссылка для авторизации сгенерирована', {
                        type: 'success',
                        autoHideDuration: 3000
                    });
                }
                setIsLinkingStarted(true);
            } else {
                showNotification('Не удалось начать процесс привязки бота', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (error) {
            console.error('Error starting bot linking process:', error);
            showNotification('Не удалось начать процесс привязки бота', {
                type: 'error',
                autoHideDuration: 3000
            });
        }
    };

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(redirectUrl)
            .then(() => showNotification('Ссылка успешно скопирована', {
                type: 'success',
                autoHideDuration: 3000
            }))
            .catch(() => showNotification('Не удалось скопировать ссылку', {
                type: 'error',
                autoHideDuration: 3000
            }));
    };

    const handleUnlinkBot = async () => {
        try {
            const response = await Requester.delete('channel/bot/');
            if (response.ok) {
                showNotification('Бот успешно отвязан', {
                    type: 'success',
                    autoHideDuration: 3000
                });
                setBotInfo(null);
                setShowRelink(false);
            } else {
                showNotification('Не удалось отвязать бота', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (error) {
            console.error('Error unlinking bot:', error);
            showNotification('Не удалось отвязать бота', {
                type: 'error',
                autoHideDuration: 3000
            });
        }
    };

    const renderLinkingProcess = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h6">
                {botInfo ? 'Перепривязка бота' : 'Подключение бота'}
            </Typography>
            
            <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="body1" paragraph>
                    {botInfo 
                        ? 'Для перепривязки бота необходимо авторизовать Twitch аккаунт бота.'
                        : 'Для использования бота необходимо привязать аккаунт Twitch, который будет использоваться как бот.'}
                </Typography>
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <strong>Важная информация:</strong>
                    <ul style={{ marginTop: 8, marginBottom: 0 }}>
                        <li>Мы настоятельно рекомендуем использовать отдельный аккаунт Twitch для бота, отличный от вашего основного аккаунта стримера</li>
                        <li>Убедитесь, что вы вошли в Twitch под нужным аккаунтом перед переходом по ссылке</li>
                        <li>Ссылка для авторизации действительна в течение 1 часа</li>
                    </ul>
                </Typography>
            </Alert>

            {!isLinkingStarted ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        variant="contained"
                        onClick={handleStartLinkingProcess}
                        startIcon={<SmartToyIcon />}
                        sx={{ minWidth: 200 }}
                    >
                        {botInfo ? 'Перепривязать бота' : 'Начать процесс привязки'}
                    </Button>
                </Box>
            ) : (
                <>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Пожалуйста, откройте следующую ссылку в браузере, где вы авторизованы под аккаунтом бота в Twitch
                    </Alert>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <TextField
                                fullWidth
                                value={redirectUrl}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <IconButton 
                                onClick={handleCopyUrl}
                                color="primary"
                                sx={{ ml: 1 }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="outlined"
                                onClick={handleStartLinkingProcess}
                                startIcon={<SmartToyIcon />}
                            >
                                Сгенерировать новую ссылку
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );

    const renderBotInfo = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h6">
                Информация о боте
            </Typography>
            
            <Alert severity={botInfo.is_alive ? "success" : "warning"} sx={{ mb: 2 }}>
                {botInfo.is_alive 
                    ? 'Бот успешно подключен и готов к работе'
                    : 'Бот не активен. Необходимо отвязать бота и выполнить привязку заново.'}
            </Alert>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="body1">
                    <strong>Имя бота:</strong> {botInfo.bot_name}
                </Typography>
                <Typography variant="body1">
                    <strong>Twitch ID:</strong> {botInfo.twitch_id}
                </Typography>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleUnlinkBot}
                >
                    Отвязать бота
                </Button>
            </Box>
        </Box>
    );

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                <Typography>Loading...</Typography>
            </Container>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                            Главная
                        </Link>
                        <Typography color="primary">
                            Управление ботом
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <SmartToyIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            Управление ботом
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {!hasFeatureAccess('owner_bot') ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 4 }}>
                                        <LockIcon sx={{ fontSize: 48, color: 'action.disabled' }} />
                                        <Typography variant="h6" textAlign="center">
                                            Функция управления ботом недоступна
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" textAlign="center">
                                            Эта функция доступна только с подпиской, включающей собственный бот.
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        {error && (
                                            <Alert severity="error" sx={{ mb: 3 }}>
                                                {error}
                                            </Alert>
                                        )}

                                        {botInfo && botInfo.is_alive ? renderBotInfo() : renderLinkingProcess()}
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}