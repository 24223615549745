export const LIGHT_PURPLE = '#B975FF';
export const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)';
export const BACKGROUND_COLOR = '#18191D';
export const INPUT_BACKGROUND = '#1F2025';

export const styles = {
    dialog: {
        borderRadius: '16px',
        bgcolor: BACKGROUND_COLOR,
        border: `1px solid ${LIGHT_PURPLE}20`,
        maxWidth: '600px',
        position: 'relative',
    },
    dialogTitle: {
        p: 3,
        borderBottom: `1px solid ${LIGHT_PURPLE}20`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleText: {
        color: LIGHT_PURPLE,
        fontWeight: 600,
        fontSize: '1.5rem',
    },
    closeButton: {
        color: 'grey.500',
        position: 'absolute',
        right: 8,
        top: 8,
        '&:hover': {
            color: LIGHT_PURPLE,
        },
    },
    dialogContent: {
        p: 3,
        position: 'relative',
    },
    stepper: {
        mb: 4,
        mt: 2,
        '& .MuiStepIcon-root': {
            '&.Mui-active, &.Mui-completed': {
                color: LIGHT_PURPLE,
            }
        }
    },
    planTitle: {
        mb: 1,
        color: '#FFFFFF',
        fontSize: '1.25rem',
        fontWeight: 500,
        '& strong': {
            color: LIGHT_PURPLE,
        },
    },
    planPrice: {
        mb: 3,
        color: 'rgba(255, 255, 255, 0.9)',
        fontSize: '1.1rem',
        '& strong': {
            color: '#FFFFFF',
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: INPUT_BACKGROUND,
            borderRadius: '12px',
        },
    },
    warningAlert: {
        mt: 2,
        backgroundColor: 'rgba(237, 108, 2, 0.15)',
        borderRadius: '8px',
    },
    errorAlert: {
        backgroundColor: 'rgba(211, 47, 47, 0.15)',
        borderRadius: '8px',
    },
    dialogActions: {
        px: 3,
        py: 2,
        borderTop: `1px solid ${LIGHT_PURPLE}20`,
        bgcolor: 'rgba(0, 0, 0, 0.2)',
    },
    cancelButton: {
        color: 'grey.300',
        px: 3,
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    nextButton: {
        backgroundColor: LIGHT_PURPLE,
        color: '#FFFFFF',
        px: 4,
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: '#9B5FE3',
        },
        '&:disabled': {
            backgroundColor: `${LIGHT_PURPLE}40`,
        },
    },
    select: {
        backgroundColor: INPUT_BACKGROUND,
        borderRadius: '12px',
        '& .MuiSelect-select': {
            color: '#FFFFFF',
        },
    },
}; 