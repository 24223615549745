import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import Requester from '../utils/requester';
import { useNotification } from '../context/NotificationContext';

export default function DonationAlertsRedirect() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    useEffect(() => {
        const processAuth = async () => {
            const code = searchParams.get('code');
            if (!code) {
                showNotification('Authentication failed: No code provided', {
                    type: 'error',
                    autoHideDuration: 3000
                });
                navigate('/dashboard/youtube-proxy');
                return;
            }

            try {
                const response = await Requester.post('donation_alerts/process_da_access_code/', {
                    code: code
                });

                if (response.ok) {
                    showNotification('Successfully connected to Donation Alerts', {
                        type: 'success',
                        autoHideDuration: 3000
                    });
                } else {
                    showNotification('Failed to connect to Donation Alerts', {
                        type: 'error',
                        autoHideDuration: 3000
                    });
                }
            } catch (error) {
                console.error('Error processing auth:', error);
                showNotification('Error connecting to Donation Alerts', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }

            // Redirect back to YouTube Proxy page regardless of outcome
            navigate('/dashboard/youtube-proxy');
        };

        processAuth();
    }, [searchParams, navigate, showNotification]);

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 3
                }}
            >
                <CircularProgress size={60} />
                <Typography variant="h6" textAlign="center">
                    Processing your Donation Alerts authentication...
                </Typography>
                <Typography variant="body1" color="text.secondary" textAlign="center">
                    Please wait while we complete the connection.
                </Typography>
            </Box>
        </Container>
    );
} 