import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Requester from "../utils/requester";

const UserContext = createContext(null);

export const hasActiveSubscription = (user) => {
  if (!user || !user.current_subscription) return false;
  return !!user.current_subscription.active_until_dt;
};

export const hasFeatureAccess = (user, feature) => {
  if (!user || !user.current_subscription) return false;
  return user.current_subscription.subscription_type.feature_tags?.includes(feature) || false;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      // Only fetch user data if we're on a dashboard page
      if (!location.pathname.startsWith('/dashboard')) {
        return;
      }

      setLoading(true);
      try {
        const response = await Requester.get('user/account_info/');
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [location.pathname]);

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      loading, 
      hasActiveSubscription: () => hasActiveSubscription(user),
      hasFeatureAccess: (feature) => hasFeatureAccess(user, feature)
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
