import { useState, useEffect } from "react";
import Requester from "../../utils/requester";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";

export default function AuthRequired() {
    const [isAuthorized, setIsAuthorized] = useState(null);
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        const validateAuth = async () => {
            try {
                const res = await Requester.validateToken();
                setIsAuthorized(res);
                if (!res) {
                    showNotification('Ваша сессия истекла. Пожалуйста, войдите снова.', {
                        type: 'warning',
                        autoHideDuration: 5000
                    });
                    navigate('/', { replace: true });
                }
            } catch (err) {
                setIsAuthorized(false);
                showNotification('Ваша сессия истекла. Пожалуйста, войдите снова.', {
                    type: 'warning',
                    autoHideDuration: 5000
                });
                navigate('/', { replace: true });
            }
        };
        validateAuth();
    }, [showNotification, navigate]);

    if (isAuthorized === null) {
        return null; // or a loading spinner
    }

    return isAuthorized ? <Outlet /> : null;
}
