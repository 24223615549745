import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Alert,
    Breadcrumbs,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    Box,
    Button,
    TextField,
    IconButton,
    Tooltip
} from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import LockIcon from '@mui/icons-material/Lock';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Requester from "../../../utils/requester";
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';

export default function YoutubeProxy() {
    const { showNotification } = useNotification();
    const { hasFeatureAccess, user } = useUser();
    const [loading, setLoading] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [proxyLinks, setProxyLinks] = useState(null);

    useEffect(() => {
        const fetchProxyLinks = async () => {
            if (user?.da_media_token_set) {
                try {
                    const response = await Requester.get('channel/youtube_proxy/');
                    if (response.ok) {
                        const data = await response.json();
                        setProxyLinks(data);
                    }
                } catch (error) {
                    console.error('Error fetching proxy links:', error);
                }
            }
        };

        fetchProxyLinks();
    }, [user?.da_media_token_set]);

    const handleCopyToClipboard = (text, description) => {
        navigator.clipboard.writeText(text).then(() => {
            showNotification(`${description} скопирован в буфер обмена`, {
                type: 'success',
                autoHideDuration: 3000
            });
        }).catch(() => {
            showNotification('Ошибка при копировании в буфер обмена', {
                type: 'error',
                autoHideDuration: 3000
            });
        });
    };

    const handleUpdateMediaToken = async () => {
        if (!mediaUrl.trim()) {
            showNotification('Пожалуйста, введите URL медиа', {
                type: 'error',
                autoHideDuration: 3000
            });
            return;
        }

        setLoading(true);
        try {
            const response = await Requester.post('donation_alerts/set_media_token/', {
                url: mediaUrl.trim()
            });

            if (response.ok) {
                showNotification('Медиа токен успешно обновлен', {
                    type: 'success',
                    autoHideDuration: 3000
                });
                // Refresh the page to update user data
                window.location.reload();
            } else {
                showNotification('Не удалось обновить медиа токен', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (error) {
            console.error('Error updating media token:', error);
            showNotification('Ошибка при обновлении медиа токена', {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                            Главная
                        </Link>
                        <Typography color="primary">
                            YouTube Proxy
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <YouTubeIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                            <Typography variant="h4" fontWeight="500">
                                YouTube Proxy
                            </Typography>
                        </Box>
                        <Button
                            component={RouterLink}
                            to="https://docs.jeetbot.cc/"
                            variant="contained"
                            color="primary"
                            startIcon={<MenuBookIcon />}
                            sx={{
                                minWidth: 200,
                                height: '44px',
                                textTransform: 'none',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Как использовать
                        </Button>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {!hasFeatureAccess('youtube_proxy') ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 4 }}>
                                        <LockIcon sx={{ fontSize: 48, color: 'action.disabled' }} />
                                        <Typography variant="h6" textAlign="center">
                                            Функция YouTube Proxy недоступна
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" textAlign="center">
                                            Эта функция доступна только с подпиской, включающей YouTube Proxy.
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                <Typography variant="h6">
                                                    Настройки медиа Donation Alerts
                                                </Typography>
                                                
                                                {user?.da_media_token_set ? (
                                                    <Alert severity="success" sx={{ mb: 2 }}>
                                                        Медиа токен уже установлен. При необходимости вы можете обновить его ниже.
                                                    </Alert>
                                                ) : (
                                                    <Alert severity="warning" sx={{ mb: 2 }}>
                                                        Пожалуйста, установите URL медиа Donation Alerts для активации функции YouTube Proxy.
                                                    </Alert>
                                                )}

                                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                                    <TextField
                                                        label="URL медиа Donation Alerts"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={mediaUrl}
                                                        onChange={(e) => setMediaUrl(e.target.value)}
                                                        placeholder="Введите URL медиа Donation Alerts"
                                                        helperText="Вы можете найти этот URL в настройках виджета Donation Alerts"
                                                        disabled={loading}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleUpdateMediaToken}
                                                        disabled={loading}
                                                        sx={{ minWidth: '120px', height: '56px' }}
                                                    >
                                                        {loading ? 'Обновление...' : (user?.da_media_token_set ? 'Обновить' : 'Установить')}
                                                    </Button>
                                                </Box>

                                                {user?.da_media_token_set && proxyLinks && (
                                                    <Box sx={{ mt: 4 }}>
                                                        <Typography variant="h6" sx={{ mb: 2 }}>
                                                            Ссылки YouTube Proxy
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                                                <TextField
                                                                    label="Ссылка для просмотра"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={proxyLinks.youtube_proxy_link}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        endAdornment: (
                                                                            <Tooltip title="Скопировать ссылку">
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    onClick={() => handleCopyToClipboard(proxyLinks.youtube_proxy_link, 'URL для просмотра')}
                                                                                >
                                                                                    <ContentCopyIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                                                <TextField
                                                                    label="Ссылка для управления"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={proxyLinks.youtube_proxy_control_link}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        endAdornment: (
                                                                            <Tooltip title="Скопировать ссылку">
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    onClick={() => handleCopyToClipboard(proxyLinks.youtube_proxy_control_link, 'URL для управления')}
                                                                                >
                                                                                    <ContentCopyIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
} 