import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Paper,
    Button
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Requester from '../utils/requester';
import { useNotification } from '../context/NotificationContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ym_hit } from "../utils/yandex";

const LIGHT_PURPLE = '#B975FF';
const MAX_POLLING_ATTEMPTS = 30;
const POLLING_INTERVAL = 5000;

export default function GiftPaymentSuccess() {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('PENDING');
    const [pollingCount, setPollingCount] = useState(0);
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    ym_hit('/gift_payment_success');

    useEffect(() => {
        const orderId = searchParams.get('order_id');
        if (!orderId) {
            showNotification('Не найден ID заказа', {
                type: 'error',
                autoHideDuration: 6000
            });
            navigate('/');
            return;
        }

        const checkOrderStatus = async () => {
            try {
                const response = await Requester.get(`subscriptions/order/status/?order_id=${orderId}`);
                if (response.ok) {
                    const data = await response.json();
                    setStatus(data.status);
                    
                    if (data.status === 'PENDING' && pollingCount < MAX_POLLING_ATTEMPTS) {
                        setTimeout(() => {
                            setPollingCount(prev => prev + 1);
                        }, POLLING_INTERVAL);
                    }
                } else {
                    setStatus('FAILED');
                }
            } catch (error) {
                console.error('Error checking order status:', error);
                setStatus('FAILED');
            }
        };

        checkOrderStatus();
    }, [pollingCount, navigate, searchParams, showNotification]);

    const renderContent = () => {
        switch (status) {
            case 'SUCCESS':
                return (
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                        <CheckCircleIcon sx={{ fontSize: 60, color: '#4CAF50', mb: 2 }} />
                        <Typography variant="h5" gutterBottom>
                            Оплата прошла успешно!
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'grey.300' }}>
                            Спасибо за покупку! Подписка будет активирована в ближайшее время.
                        </Typography>
                    </Box>
                );
            case 'FAILED':
                return (
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                        <ErrorIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                        <Typography variant="h5" gutterBottom>
                            Ошибка при оплате
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'grey.300' }}>
                            К сожалению, произошла ошибка при обработке платежа. Пожалуйста, попробуйте еще раз.
                        </Typography>
                    </Box>
                );
            case 'PENDING':
                return (
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                        <CircularProgress size={60} sx={{ mb: 2, color: LIGHT_PURPLE }} />
                        <Typography variant="h5" gutterBottom>
                            Обработка платежа
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'grey.300' }}>
                            Мы ожидаем подтверждение от платежной системы. Вы можете вернуться на главную страницу, 
                            подписка будет активирована автоматически после получения подтверждения.
                        </Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    gap: 3
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        width: '100%',
                        bgcolor: 'rgba(31, 32, 37, 0.95)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 2,
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    {renderContent()}
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => navigate('/')}
                        sx={{
                            backgroundColor: LIGHT_PURPLE,
                            '&:hover': {
                                backgroundColor: '#9B5FE3',
                            },
                        }}
                    >
                        Вернуться на главную
                    </Button>
                </Paper>
            </Box>
        </Container>
    );
} 